import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Image1 from '../assets/ciudades/san-cristobal.png';
import Image2 from '../assets/ciudades/colon.png';
import Image3 from '../assets/ciudades/tariba.png';

const LocationsPage = () => (
    <Layout>
        <SEO
            title="¿Donde entregan botellones? - Aguaman"
            pathname="/ciudades"
            description="San Cristóbal: Barrio Obrero, Barrio Sucre, Centro, La Concordia, La Castellana, Pirineos I y II, etc. San Juan de Colón: Ayacucho, Centro"
        />
        <section className="faqs-prop">
            <div className="faqs-prop-content">
                <div className="faqs-title">
                    <h1>
                        <span role="img" aria-label="map-pin">
                            📍
                        </span>{' '}
                        Ciudades
                    </h1>
                    <hr />
                </div>
                <div className="accordion-wrapper">
                    <br />
                    <div className="row">
                        <div class="col">
                            <div class="tabs">
                                <div class="tab">
                                    <input type="checkbox" id="chck1" />
                                    <label class="tab-label" for="chck1">
                                        🇻🇪 San Cristóbal
                                    </label>
                                    <div class="tab-content list-places">
                                        <img
                                            src={Image1}
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="tab">
                                    <input type="checkbox" id="chck2" />
                                    <label class="tab-label" for="chck2">
                                        🇻🇪 Colón
                                    </label>
                                    <div class="tab-content list-places">
                                        <img
                                            src={Image2}
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="tab">
                                    <input type="checkbox" id="chck3" />
                                    <label class="tab-label" for="chck3">
                                        🇻🇪 Táriba
                                    </label>
                                    <div class="tab-content list-places">
                                        <img
                                            src={Image3}
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <section className="cta">
                    <h3>¡Pide tu botellón a domicilio!</h3>
                    <Link className="button" to="/descargar">
                        Quiero usar el servicio
                    </Link>
                </section>
            </div>
        </section>
    </Layout>
);

export default LocationsPage;
